import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageBanner from "../components/page-banner"
import BlogSingle from "../components/blog-single"
import BlogRelation from "../components/blog-raletion"
import BlogContent from "../components/blog-content"
import HomeContact from "../components/home-contact"

const Page = ({ data }) => {
  const currentPost = data.currentPost
  const mainPage = data.mainPage
  const relatedPosts = data.relatedPosts.nodes

  return (
    <Layout>
      <SEO post={currentPost} />
      <PageBanner post={mainPage} />
      <BlogContent>
        <BlogSingle post={currentPost} />
      </BlogContent>
      <BlogRelation posts={relatedPosts} />
      <HomeContact />
    </Layout>
  )
}

export default Page

export const pageQuery = graphql`
  query($id: String!, $primary_category_id: Int) {
    currentPost: wordpressPost(id: {eq: $id }) {
      ...PostFull
    }

    relatedPosts: allWordpressPost(
      limit: 10,
      filter: {id: {nin: [$id]}, primary_category_id: {eq: $primary_category_id}, status: {eq: "publish"}},
      sort: {order: DESC, fields: date}
    ) {
      nodes {
        ...PostItem
      }
    }

    mainPage: wordpressMainPage(name: {eq: "blog-e-receitas"}) {
      ...MainPageFull
    }
  }
`
