import React from "react"
import PreviewPostPage from "../../components/preview-page"
import Template from "../../templates/blog-single"

const Page = () => (
  <PreviewPostPage
    template={Template}
    mainDataKey="currentPost"
    additionalData={{ relatedPosts: { nodes: [] } }}
    postType="posts" />
)

export default Page
